import {
  MENU_ITEM_ID_ADMIN,
  MENU_ITEM_ID_MY_ACCOUNT,
  MENU_ITEM_ID_OPEN_ORDER,
  MENU_ITEM_ID_ORDER,
  MENU_ITEM_ID_ORDER_MANAGEMENT,
  MENU_ITEM_ID_POINTS_AND_REWARDS,
  MENU_ITEM_ID_POWER_USER,
  MENU_ITEM_ID_UPLOAD_PART_FILE,
} from '../constants/menuServiceConstants'
import { MenuItem } from '../hooks/services/graphql/useMenuItemListService'
import { NavigationItem } from '../types/layoutService'

export const getMenuItem = (
  itemId: string,
  menuItems?: MenuItem[]
): MenuItem | undefined => menuItems && menuItems.find(({ id }) => id === itemId)

export const getBasketMenuItems = (
  menuItems?: MenuItem[]
): MenuItem[] | undefined => {
  if (menuItems) {
    return getMenuItem(MENU_ITEM_ID_ORDER, menuItems)?.subMenuItems?.filter(
      ({ id }) =>
        id !== MENU_ITEM_ID_OPEN_ORDER && id !== MENU_ITEM_ID_UPLOAD_PART_FILE
    )
  }

  return undefined
}

export const getBasketOpenOrderItem = (
  menuItems?: MenuItem[]
): MenuItem | undefined =>
  menuItems &&
  getMenuItem(MENU_ITEM_ID_ORDER, menuItems)?.subMenuItems?.find(
    ({ id }) => id === MENU_ITEM_ID_OPEN_ORDER
  )

export const getBasketMenuItem = (
  lookupId: string,
  menuItems?: MenuItem[]
): MenuItem | undefined =>
  menuItems &&
  getMenuItem(MENU_ITEM_ID_ORDER, menuItems)?.subMenuItems?.find(
    ({ id }) => id === lookupId
  )

export const getMyAccountMenuItems = (
  menuItems?: MenuItem[]
): MenuItem[] | undefined =>
  menuItems &&
  menuItems.filter(({ id }) =>
    [
      MENU_ITEM_ID_MY_ACCOUNT,
      MENU_ITEM_ID_ADMIN,
      MENU_ITEM_ID_ORDER_MANAGEMENT,
      MENU_ITEM_ID_POWER_USER,
      MENU_ITEM_ID_POINTS_AND_REWARDS,
    ].includes(id)
  )

export const sortMenuByPriority = (menuItems: MenuItem[]): MenuItem[] =>
  (!!menuItems.length &&
    menuItems
      .reduce<MenuItem[]>(
        (a, b) => [
          ...a,
          {
            ...b,
            subMenuItems: b?.subMenuItems?.length
              ? sortMenuByPriority(b.subMenuItems)
              : [],
          },
        ],
        []
      )
      .sort((a, b) => a.priority - b.priority)) ||
  []

export const findMenuItemByPath = (
  menuItems: MenuItem[],
  path: string // dot separated string
): MenuItem | undefined => {
  const pathSegments = path.split('.')
  const item = menuItems.find(({ id }) => id === pathSegments[0])

  if (item?.subMenuItems?.length && pathSegments && pathSegments.length > 1) {
    const newPath = pathSegments.slice(1, pathSegments.length)

    if (newPath.length) {
      return findMenuItemByPath(item.subMenuItems, newPath.join('.'))
    }

    return undefined
  }

  return item
}

export const mapMenuServiceItemToNavigationItem = (
  menuItem: MenuItem
): NavigationItem => ({
  type: 'general',
  title: menuItem.description,
  url: menuItem.url,
  dispatchAction: null,
  hasSubMenu: !!menuItem.subMenuItems?.length,
  children:
    menuItem.subMenuItems?.map((subMenuItem) =>
      mapMenuServiceItemToNavigationItem(subMenuItem)
    ) || [],
})

import React, { useContext, useMemo } from 'react'
import { Link } from 'theme-ui'
import { getMyAccountMenuItems } from '../../../helpers/menuHelper'
import { getUserFullName, hasRole } from '../../../helpers/userHelper'
import { MenuProviderContext } from '../../../providers/MenuProvider'
import { UserContext } from '../../../providers/UserProvider'
import ActionItemBase from '../../atoms/ActionItemBase/ActionItemBase'
import SidePanelSection from '../SidePanel/SidePanelSection'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { AccountOverviewMenuProps } from '../../../types/accountOverviewTypes'
import {
  COMMERCE_SITE_CONTEXT,
  MULTISUPPORT_SITE_CONTEXT,
} from '../../../constants/environmentConstants'
import {
  USER_ROLES_MULTISUPPORT_REPAIR_AND_MAINTENANCE,
  USER_ROLES_PARTS_ACCESS,
} from '../../../constants/userConstants'

interface MyAccountMenuProps {
  accountOverviewMenuItemsMultisupport: AccountOverviewMenuProps
  accountOverviewMenuItemsCommerce: AccountOverviewMenuProps
}

const MyAccountMenu = ({
  accountOverviewMenuItemsMultisupport: accountOverviewMenuItems,
  accountOverviewMenuItemsCommerce: commerceAccountOverviewMenuItems,
}: MyAccountMenuProps) => {
  const { fetching, menu } = useContext(MenuProviderContext)
  const { user, userProfile } = useContext(UserContext)
  const { featureToggles, site } = useSitecoreContext()
  const myAccountSidepanelNavigationEnabled =
    featureToggles?.myAccountSidepanelNavigation ?? false
  const accountMenuItemsMultisupport =
    accountOverviewMenuItems?.targetItem?.menuItems?.results
  const accountMenuItemsCommerce =
    commerceAccountOverviewMenuItems?.targetItem?.menuItems?.results
  const isParts = site?.name === COMMERCE_SITE_CONTEXT
  const menuItems = useMemo(() => getMyAccountMenuItems(menu), [menu])
  const userFullName = useMemo(() => getUserFullName(userProfile), [userProfile])

  const showAccountMenuItemsOnMultisupport =
    site?.name === MULTISUPPORT_SITE_CONTEXT &&
    hasRole(user, USER_ROLES_MULTISUPPORT_REPAIR_AND_MAINTENANCE) &&
    myAccountSidepanelNavigationEnabled

  const showAccountMenuItemsOnCommerce =
    site?.name === COMMERCE_SITE_CONTEXT && hasRole(user, USER_ROLES_PARTS_ACCESS)

  if (!userProfile) return null
  return (
    <SidePanelSection title={userFullName} suppressTitle>
      {!fetching && (
        <nav>
          {showAccountMenuItemsOnCommerce &&
            accountMenuItemsCommerce
              ?.filter(
                (item) =>
                  item?.linkItem?.url !== '/logout' &&
                  !item.hideMyAccountMenu.boolValue
              )
              .map((item) => (
                <Link key={item?.linkItem?.url} href={item?.linkItem?.url}>
                  <ActionItemBase>{item?.linkItem?.text}</ActionItemBase>
                </Link>
              ))}
          {showAccountMenuItemsOnMultisupport &&
            accountMenuItemsMultisupport
              ?.filter(
                (item) =>
                  item?.linkItem?.url !== '/logout' &&
                  !item.hideMyAccountMenu.boolValue
              )
              .map((item) => (
                <Link key={item?.linkItem?.url} href={item?.linkItem?.url}>
                  <ActionItemBase>{item?.linkItem?.text}</ActionItemBase>
                </Link>
              ))}
          {isParts &&
            menuItems?.map(({ description, id, url }) => (
              <Link key={id} href={url} data-t-id={`my-account-link-${id}`}>
                <ActionItemBase>{description}</ActionItemBase>
              </Link>
            ))}
        </nav>
      )}
    </SidePanelSection>
  )
}

export default MyAccountMenu
